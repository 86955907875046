<ng-container *ngIf="content$ | async as content">
    <div class="hero">
        <svg viewBox="0 0 100 15" style="position: absolute;bottom: 0;left: 0;z-index: 0;">
            <path fill="#fff" opacity="0.1" d="M0 30 V15 Q30 3 60 15 V30z"></path>
            <path class="wave-fill" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
        </svg>
        <material-navbar [transparent]="true" [container]="true" menuPosition="homepage-navbar"></material-navbar>
        <div class="container">
            <h1 class="header-title">{{content.headerTitle}}</h1>
            <p class="header-description">{{content.headerSubtitle}}</p>
            <new-link-form *ngIf="settings.get('links.homepage_creation')" [content]="content"></new-link-form>
            <div class="action-buttons">
                <a class="get-started-button" mat-raised-button color="accent" *ngIf="content.actions?.cta1" routerLink="/login">{{content.actions.cta1}}</a>
                <button class="learn-more-button" mat-button type="button" (click)="scrollToFeatures()" *ngIf="content.actions?.cta2">{{content.actions.cta2}}</button>
            </div>
        </div>
    </div>

    <div class="container">
        <ad-host slot="ads.landing.top"></ad-host>
        <ul class="inline-features">
            <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
                <img [src]="settings.getBaseUrl(true) + primaryFeature.image">
                <h3>{{primaryFeature.title}}</h3>
                <p>{{primaryFeature.subtitle}}</p>
            </li>
        </ul>
    </div>

    <div class="spacer"></div>

    <ul class="big-features">
        <ng-container *ngFor="let secondaryFeature of content.secondaryFeatures; first as first; last as last">
            <li class="big-feature container"  [class.first-secondary-feature]="first">
                <img [src]="settings.getBaseUrl(true) + secondaryFeature.image">
                <section class="info">
                    <small>{{secondaryFeature.subtitle}}</small>
                    <h2>{{secondaryFeature.title}}</h2>
                    <div class="feature-border"></div>
                    <p>{{secondaryFeature.description}}</p>
                </section>
            </li>

            <div class="spacer" *ngIf="!last"></div>
        </ng-container>
    </ul>

    <div class="spacer"></div>

    <ng-container *ngIf="settings.get('links.homepage_stats')">
        <div class="stats container" *ngIf="stats$ | async as stats">
            <div class="stat">
                <mat-icon svgIcon="link"></mat-icon>
                <div class="info">
                    <div class="text" trans>Total Links Created</div>
                    <div class="number">{{stats.links}}</div>
                </div>
            </div>
            <div class="stat">
                <mat-icon svgIcon="mouse"></mat-icon>
                <div class="info">
                    <div class="text" trans>Total Link Clicks</div>
                    <div class="number">{{stats.clicks}}</div>
                </div>
            </div>
            <div class="stat">
                <mat-icon svgIcon="person"></mat-icon>
                <div class="info">
                    <div class="text" trans>Users Signed Up</div>
                    <div class="number">{{stats.users}}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="bottom-cta">
        <div class="container">
            <h2 class="footer-title">{{content.footerTitle}}</h2>
            <p class="footer-description">{{content.footerSubtitle}}</p>
            <a mat-raised-button type="button" routerLink="/login" *ngIf="content.actions?.cta1">{{content.actions.cta1}}</a>
        </div>
    </div>

    <footer>
        <div class="container">
            <div class="meta">
                <span>{{copyrightText()}}, </span>
                <a [routerLink]="'/'">{{settings.get('branding.site_name')}}</a>
            </div>
            <custom-menu position="footer" [horizontal]="true"></custom-menu>
        </div>
    </footer>
</ng-container>